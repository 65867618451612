import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingScreen from './components/Desktop/LoadingScreen';
import axios from 'axios';

const queryClient = new QueryClient();

const Root = React.lazy(() => import('./pages/Root'));
const Palvelut = React.lazy(() => import('./pages/Palvelut'));
const Kohteet = React.lazy(() => import('./pages/Kohteet'));

export default function App() {
  const sendIndexNowRequest = async () => {
    try {
      const url = 'http://faindibackend-production.up.railway.app/sendindexing'; // Replace with your backend endpoint URL
      const queryParams = {
        url: '',
        key: 'd6af5518a650406082dd141cb6bf8bbd'
      };

      await axios.post(url, queryParams);
      console.log('IndexNow request sent successfully!');
    } catch (error) {
      console.error('Error sending IndexNow request:', error);
    }
  };

  // Execute the sendIndexNowRequest function when the component mounts
  React.useEffect(() => {
    sendIndexNowRequest();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            {/* No need for isLoaded flag, Suspense handles this */}
            <Route element={<Root />} path="/" />
            <Route element={<Palvelut />} path="/palvelut" />
            <Route element={<Kohteet />} path="/kohteet" />
          </Routes>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}
