import React from 'react';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
`;

const StyledLoader = () => {
  return (
    <BarLoader
      color="#808080" // Set the loader color to grey
      loading={true}
    />
  );
};

const LoadingComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'black', // Set the background color to black
      }}
    >
      <StyledLoader />
    </div>
  );
};

export default LoadingComponent;
